<template>
    <div class="work">
        <div class="pa-10 cardtype">
            <p class="mb-1 h6 dark1 locationtype" v-html="item.work_type"></p>
            <p class="h4 bold mb-2" v-html="item.title"></p>
            <p class="mb-0 h6 dark1">$ <span class="salary">Salary</span></p>
            <p class="h5 dark1 mb-10 fontw-700" v-html="item.price"></p>
            <div @click="dialog = true">
                <button-circle>Read more <v-icon x-small class="ml-3">mdi-plus</v-icon></button-circle>
            </div>
        </div>
        <v-dialog
            v-model="dialog"
            :width="$vuetify.breakpoint.lgAndUp ? '1240' : false"
            :fullscreen="$vuetify.breakpoint.mdAndDown"
            content-class="disable-box-shadow elevation-0"
            :content-class="$vuetify.breakpoint.mdAndDown ? 'nonrounded' : ''"
            overlay-color="#323232"
            overlay-opacity="0.3"
        >
            <div class="workpopup py-sm-4 px-sm-6 p-relative">
                <div class="text-right py-4 p-absolute zi-2" style="right:0">
                    <v-btn text retain-focus-on-click class="montserrat removebefore fontw-700" @click="dialog = false" :ripple="false">
                        Close <v-icon class="ml-2">mdi-close</v-icon>
                    </v-btn>
                </div>
                <div class="content px-3 px-md-13 py-md-7">
                    <div class="h2-3 pt-sm-2 montserrat fontw-700 mb-3 pt-13" v-html="item.title"></div>
                    <div class="d-flex">
                        <div class="mr-10">
                            <p class="mb-2 h6 text-silver">$ <span class="salary">Salary</span></p>
                            <p class="h5 dark1 mb-5 fontw-700" v-html="item.price"></p>
                        </div>
                        <div class="">
                            <p class="mb-2 h6 text-silver d-flex justify-center"><img src="@/assets/icon/seniority.svg" class="mr-1"> <span class="salary">Seniority level</span></p>
                            <p class="h5 dark1 mb-5 fontw-700" v-html="item.level"></p>
                        </div>
                    </div>
                    <p v-if="item.description" class="h5-5 opensans rowtextdescription" v-html="item.description"></p>
                    <v-row v-if="item.responsibilities" class="mt-10">
                        <v-col cols="12" lg="4" class="h4 montserrat fontw-700">
                            Responsibilities:
                        </v-col>
                        <v-col cols="12" lg="8" class="h5-5 opensans rowtextdescription" v-html="item.responsibilities"></v-col>
                    </v-row>
                    <v-row v-if="item.qualification">
                        <v-col cols="12" lg="4" class="h4 montserrat fontw-700">
                            Required Qualifications:
                        </v-col>
                        <v-col cols="12" lg="8" class="h5-5 opensans rowtextdescription" v-html="item.qualification"></v-col>
                    </v-row>
                </div>
                <div class="content mt-8 px-3 px-md-13 py-7" style="background: #F7F7F7;">
                    <div v-if="!sended" class="">
                        <div class="h3-2 dark1 fontw-700 mb-6 montserrat">Apply now</div>
                        <v-row>
                            <v-col cols="12" lg="8">
                                <v-form
                                    lazy-validation
                                    ref="form"
                                    v-model="valid"
                                >
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6" for="">Your name</label>
                                            <v-text-field
                                                v-model="form.name"
                                                color="#323232"
                                                class="py-0"
                                                :rules="requiredRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6" for="">Your Email</label>
                                            <v-text-field
                                                v-model="form.email"
                                                color="#323232"
                                                class="py-0"
                                                :rules="emailRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6" for="">Phone number</label>
                                            <v-text-field
                                                v-model="form.phone"
                                                color="#323232"
                                                class="py-0"
                                                :rules="phoneRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6" for="">Add your CV</label>
                                            <v-file-input
                                                v-model="form.cv"
                                                solo
                                                flat
                                                placeholder="Choose File"
                                                color="#1E5CFB"
                                                class="py-0 fileinout"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <div class="d-flex align-md-center flex-column flex-md-row">
                                        <custom-button class="px-14 py-7 white--text fontw-700 btn-border-radius mb-3 btnfullblue d-flex" large @click="apply">Apply</custom-button>
                                          <div class="ml-md-6 h6 opensans">
                                              By submitting the form you <br/>agree with <router-link :to="{ name: 'frontend.terms', params: {} }" class="dark1 text-decoration-underline">terms and conditions</router-link>
                                          </div>
                                    </div>
                                </v-form>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-if="sended">
                        <v-col cols="12" lg="9">
                            <div class="h3-2 fontw-700">
                                Thank You! We have received your message, and will get back to you soon
                            </div>
                            <custom-button class="py-7 text-uppercase px-14 my-2 mt-10 btn-border-radius btnfullblue white--text fontw-700 h6" large @click="dialog = false">Continue</custom-button>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>

import CustomButton from '@/components/base/CustomButton.vue'
import ValidationMixin from '@/mixins/validationMixin.js';
import { serialize } from 'object-to-formdata';

export default {
    name: 'JobCard',
    components: {
        CustomButton
    },
    mixins: [
        ValidationMixin
    ],
    props: ['item'],
    data: () => ({
        dialog: false,
        sended: false,
        valid: false,
        form: {
            phone: '',
            email: '',
            text: '',
            cv: null
        }
    }),
    methods: {
        apply(){
            if(this.$refs.form.validate()){
                this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

                const formData = serialize(
                    this.form,
                    {
                        indices: true,
                        allowEmptyArrays: true
                    }
                );
                formData.append('_method', 'PUT');
                this.axios.post(process.env.VUE_APP_API + 'careers', formData).then(response => {
                    this.sended = true;
                    this.$refs.form.reset();
                });
            }
        }
    }
}
</script>

<style lang="scss">
.work{
    background: #f7f7f7;
    border-radius: 40px;
    .locationtype, .salary{
        opacity: 0.6;
    }
}
.v-dialog{
    border-radius: 20px !important;
}
.workpopup{
    background: #FFFFFF;
    border-radius: 20px !important;
    .rowtextdescription{
        color: #707070;
        ul li::marker {
            color: #1E5CFB;
        }
        p{
            margin-bottom: 10px;
        }
    }
    .fileinout{
        .v-input__slot{
            padding-left: 0px !important;
        }
        button{
            color: #1E5CFB !important;
        }
        .v-file-input__text--placeholder{
            color: #1E5CFB !important;
            text-decoration: underline;
            font-family: "Montserrat"
        }
    }
}

</style>
