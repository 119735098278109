<template>
    <div class="">
        <div class="bg-blue">
            <v-container>
                <div class="py-10 my-lg-16 py-lg-10">
                    <h1 class="h1 montserrat text-center text-md-left white--text">Blog</h1>
                    <v-col cols="12" lg="9" class="my-6 opensans text-center  text-md-left white--text h5">Gain new insights into the technology and software industry regularly with our recent blog posts based on real-life projects and the unique expertise of our developers.</v-col>
                </div>
            </v-container>
        </div>
        <v-container class="my-16">
            <wait-loading v-if="loading"/>
            <blog-block :items="posts"/>
        </v-container>
    </div>
</template>

<script>
import BlogBlock from '@/components/base/BlogBlock.vue'
import WaitLoading from '@/components/base/WaitLoading.vue'

export default {
    metaInfo() {
        return {
            meta: [
                { name: 'description', content:  'Keep up to date with the latest news from the IT industry. Blog posts from GBMF give you valuable insights into the constantly-developing IT industry.'},
                { property: 'og:title', content: "GBMF Blog: be constantly in touch with the IT world"},
                { property: 'og:site_name', content: 'GBMF'},
                { property: 'og:type', content: 'website'},
                { name: 'robots', content: 'index,follow'}
            ]
        }
    },
    components: {
        BlogBlock,
        WaitLoading
    },
    data: () => ({
        posts: [],
        loading: true,
    }),
    created() {
        this.$emit('headerClass', 'headerblue');
    },
    mounted(){
        this.getPosts();
    },
    methods: {
        async getPosts(){
            await this.axios.get(process.env.VUE_APP_API + 'blog').then(response => {
                this.posts = response.data;
                this.loading = false;
            })
        }
    }
}
</script>

<style lang="scss">
</style>
