import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import components from './plugins/components'

import AOS from 'aos';
import VueMeta from 'vue-meta'

import './sass/main.scss'
import 'aos/dist/aos.css';

AOS.init();

Vue.use(VueMeta)
Vue.config.productionTip = true

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  // mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
