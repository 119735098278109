<template>
    <div class="p-relative headermenumainblock">
        <div class="h-100 w-100 bg-blue headerblock px-0 px-md-4 absolute d-none" style="overflow-x:hidden;overflow-y:auto;">
            <div class="px-0 d-flex justify-space-between pa-5 montserrat relative">
                <v-list class="pa-0 d-none d-md-flex align-center" flat color="transparent">
                    <v-list-item-group>
                        <v-list-item :ripple="false">
                            <v-list-item-content>
                                <v-list-item-title style="z-index:9999;">
                                    <router-link :to="{ name: 'frontend.home', params: {} }"><img class="logowidth" :src="require('@/assets/logo/logo-white.svg')" alt="Logo"></router-link>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-list class="pa-0 zi-2" color="transparent" flat>
                    <v-list-item-group class="d-flex">
                        <v-list-item :ripple="false">
                            <!-- <v-list-item-content>
                                <v-list-item-title>
                                    <v-menu offset-y transition="slide-y-transition">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="removebefore fontw-700"
                                                :ripple="false"
                                                color="transparent"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <p class="bold montserrat d-flex align-center h-100 ma-0 fontw-600">{{dropdown_lang[lang].text}}</p>
                                                <img class="ml-4 mb-1" :src="require('@/assets/icon/vectorwhite.svg')" alt="" width="10" height="4">
                                            </v-btn>
                                        </template>
                                        <v-list flat>
                                            <v-list-item-group v-model="lang">
                                                <v-list-item v-for="(item, i) in dropdown_lang" :key="i">
                                                    <v-list-item-title>
                                                        <router-link v-if="item.text == dropdown_lang[lang].text" :to="{ name: '', params: {} }" class="h-6 blue1 montserrat fontw-700">{{ item.text }}</router-link>
                                                        <router-link v-if="item.text != dropdown_lang[lang].text" :to="{ name: '', params: {} }" class="h-6 montserrat fontw-700 dark1">{{ item.text }}</router-link>
                                                        <img v-if="item.text == dropdown_lang[lang].text" class="ml-4 mb-1" :src="require('@/assets/icon/tick.svg')" alt="" width="12">
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-title>
                            </v-list-item-content> -->
                        </v-list-item>
                        <v-list-item class="d-none d-md-flex" :ripple="false">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-img :src="require('@/assets/icon/dividerwhite.svg')" disabled/>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-none d-md-block" :ripple="false">
                            <v-list-item-content>
                                <v-list-item-title @click="$refs.getstarted.changeDialogget()">
                                        <button-standard-large large="true" outlined btnclass="btn-border-radius border-white-03 removebefore py-6 px-10 bold white--text">GET STARTED</button-standard-large>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="d-none d-md-flex" :ripple="false">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-img :src="require('@/assets/icon/dividerwhite.svg')" disabled/>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :ripple="false">
                            <v-list-item-content>
                                <v-list-item-title class="white--text uppercase h6 bold">
                                    <v-btn text retain-focus-on-click class="white--text removebefore fontw-700 pr-0" @click="closeBlock" :ripple="false">
                                        Close <v-icon class="ml-2">mdi-close</v-icon>
                                     </v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <div class="d-none d-md-block absolute bordermenurectangle" :style="'background-image:url('+popupbgimg+')'">>
                </div>
            </div>
            <v-container>
                <v-row align="center" class="lightbox white--text pa-2 fill-height">
                    <v-col class="borderbottomblue" cols="12" md="8" style="z-index:9999;">
                        <p class="d-none d-md-block h5 white--text montserrat uppercase">Menu</p>
                        <div class="d-flex" v-for="(item,i) in headermenu" :key="i" :data-aos="dialog ? 'fade-right' : ''">
                            <div class="d-flex" @mouseover="hoverbg(item)" @mouseleave="hoverbgimg(item)"><header-menu-link :item='item' @routed="closeBlock"/></div>
                        </div>
                        <router-link class="py-7 py-md-0 d-block d-md-none" :to="{ name: '', params: {} }" @click="$refs.getstarted.changeDialogget()">
                            <button-standard-large large="true" outlined btnclass="btn-border-radius border-white-03 py-6 px-10 bold white--text">GET STARTED</button-standard-large>
                        </router-link>
                    </v-col>
                    <v-col class="pt-7 pt-md-0" cols="12" md="4" style="z-index:9999;">
                        <div class="subheading">
                            <div class="mt-10">
                                <p class="h3 fontw-700 mb-2 montserrat">United Kingdom</p>
                                <p class="h6 mb-4 montserrat">London,1st Floor, 2 Woodberry Grove <br/>Finchley N12 0DR</p>
                                <a href="tel:+44 741 832 3492">
                                    <p class="h5 fontw-700 d-flex align-center mb-2 montserrat">
                                        <img :src="require('@/assets/icon/phone-call-white.svg')" class="mr-2" alt="">+44 741 832 3492
                                    </p>
                                </a>
                                <a href="mailto:hello@gbmf.tech">
                                    <p class="h5 fontw-700 d-flex align-center montserrat">
                                        <img :src="require('@/assets/icon/email-write-white.svg')" class="mr-2" alt="">hello@gbmf.tech
                                    </p>
                                </a>
                            </div>

                            <div class="mt-8">
                                <p class="h3 fontw-700 mb-2 montserrat">USA</p>
                                <p class="h6 mb-4 montserrat">Citrus Heights,California 95610, <br/>Sylvan Meadow Court 6830</p>
                                <a href="mailto:info@gbmf.tech">
                                    <p class="h5 fontw-700 d-flex align-center montserrat">
                                        <img :src="require('@/assets/icon/email-write-white.svg')" class="mr-2" alt="">info@gbmf.tech
                                    </p>
                                </a>
                            </div>
	                        <div class="mt-10">
		                        <p class="h3 fontw-700 mb-2 montserrat">Switzerland</p>
		                        <p class="h6 mb-4 montserrat">Badenerstrasse 47, 8004 Zürich</p>
		                        <a href="mailto:info@gbmf.tech">
			                        <p class="h5 fontw-700 d-flex align-center montserrat">
				                        <img :src="require('@/assets/icon/email-write-white.svg')" class="mr-2" alt="">info@gbmf.tech
			                        </p>
		                        </a>
	                        </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="d-block h-100 bg-blue headermenublock">
            <v-container :fluid="$vuetify.breakpoint.lgAndUp" class="px-0 px-md-7 py-md-8 p-relative zi-1">
                <div class="d-flex justify-space-between align-center">
                    <div class="d-none d-sm-block">
                        <router-link :to="{ name: 'frontend.home', params: {} }"><img class="logowidth" :src="require('@/assets/logo/logo-white.svg')" alt="Logo"></router-link>
                    </div>
                    <div class="d-flex justify-space-between align-center flex-grow-1 flex-sm-grow-0">
                        <div class="">
                            <!-- <v-menu offset-y transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="removebefore disable-box-shadow fontw-700"
                                        :ripple="false"
                                        color="transparent"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <p class="bold montserrat d-flex align-center h-100 ma-0 fontw-600">{{dropdown_lang[lang].text}}</p>
                                        <img class="ml-4 mb-1" :src="require('@/assets/icon/vectorwhite.svg')" alt="" width="10" height="4">
                                    </v-btn>
                                </template>
                                <v-list flat class="disable-box-shadow">
                                    <v-list-item-group v-model="lang">
                                        <v-list-item v-for="(item, i) in dropdown_lang" :key="i">
                                            <v-list-item-title>
                                                <router-link v-if="item.text == dropdown_lang[lang].text" :to="{ name: '', params: {} }" class="h-6 blue1 montserrat fontw-700">{{ item.text }}</router-link>
                                                <router-link v-if="item.text != dropdown_lang[lang].text" :to="{ name: '', params: {} }" class="h-6 montserrat fontw-700 dark1">{{ item.text }}</router-link>
                                                <img v-if="item.text == dropdown_lang[lang].text" class="ml-4 mb-1" :src="require('@/assets/icon/tick.svg')" alt="" width="12">
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu> -->
                        </div>
                        <!-- <v-img :src="require('@/assets/icon/dividerwhite.svg')" disabled class="mx-5 d-none d-sm-block"/> -->
                        <v-btn
                             class="bg-transparent removebefore white--text py-6 px-10 btn-border-radius back disable-box-shadow fontw-700 d-none d-lg-flex"
                             @click="$refs.getstarted.changeDialogget()"
                        >
                            GET STARTED
                        </v-btn>
                        <v-img :src="require('@/assets/icon/dividerwhite.svg')" disabled class="mx-5 d-none d-lg-block"/>
                        <div class="">
                            <v-btn text retain-focus-on-click class="white--text removebefore fontw-700" @click="closeBlock" :ripple="false">
                                Close <v-icon class="ml-2">mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-container>
            <img class="bgimageburgermenu d-none d-lg-block" :src="popupbgimg"/>
            <v-container class="p-relative zi-1">
                <v-row class="mt-md-12">
                    <v-col cols="12" md="6" lg="8">
                        <div class="mt-8 mt-sm-16 mt-md-6">
                            <p class="d-none d-lg-block h5 white--text montserrat uppercase">Menu</p>
                            <div v-for="(item, i) in headermenu"  class="my-2">
                                <span @mouseover="hoverbg(item)" @mouseleave="hoverbgimg(item)">
                                    <header-menu-link :item='item' @routed="closeBlock"/>
                                    <!-- <router-link  class="h2-3 fontw-700 montserrat white--text text-uppercase" :to="{ name: item.link, params: {} }" v-html="item.text"></router-link> -->
                                </span>
                            </div>
                            <v-btn
                                 class="bg-blue removebefore white--text mt-5 my-sm-10 py-7 px-10 btn-border-radius back disable-box-shadow fontw-700 d-md-none"
                                 @click="$refs.getstarted.changeDialogget()"
                            >GET STARTED</v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <div class="white--text addresses mt-6">
                            <v-row>
<!--                                <v-col cols="12">-->
<!--                                    <div class="mt-10 mt-md-6">-->
<!--                                        <p class="h3 fontw-700 mb-2 montserrat">United Kingdom</p>-->
<!--                                        <p class="h6 mb-4 montserrat">London,1st Floor, 2 Woodberry Grove <br/>Finchley N12 0DR</p>-->
<!--                                        <a href="tel:+44 741 832 3492" class="white&#45;&#45;text">-->
<!--                                            <p class="h5 fontw-700 d-flex align-center mb-2 montserrat">-->
<!--                                                <img :src="require('@/assets/icon/phone-call-white.svg')" class="mr-2" alt="">+44 741 832 3492-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                        <a href="mailto:hello@gbmf.tech" class="white&#45;&#45;text">-->
<!--                                            <p class="h5 fontw-700 d-flex align-center montserrat">-->
<!--                                                <img :src="require('@/assets/icon/email-write-white.svg')" class="mr-2" alt="">hello@gbmf.tech-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </v-col>-->
<!--                                <v-col cols="12" sm="6" md="12" class="pt-md-0">-->
<!--                                    <div class="mt-3 mt-sm-8 mt-md-0">-->
<!--                                        <p class="h3 fontw-700 mb-2 montserrat">USA</p>-->
<!--                                        <p class="h6 mb-4 montserrat">Citrus Heights,California 95610, <br/>7610 Auburn Blvd 4</p>-->
<!--                                        <a href="tel:+1 415 968 72 70" class="white&#45;&#45;text">-->
<!--                                            <p class="h5 fontw-700 d-flex align-center mb-2 montserrat">-->
<!--                                                <img :src="require('@/assets/icon/phone-call-white.svg')" class="mr-2" alt="">+1 415 968 72 70-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                        <a href="mailto:info@gbmf.tech" class="white&#45;&#45;text">-->
<!--                                            <p class="h5 fontw-700 d-flex align-center montserrat">-->
<!--                                                <img :src="require('@/assets/icon/email-write-white.svg')" class="mr-2" alt="">info@gbmf.tech-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </v-col>-->
<!--                                <v-col cols="12" sm="6" md="12" class="pt-md-0">-->
<!--									&lt;!&ndash; <div v-if="$store.state.location == 'MD'" class="mt-3 mt-sm-8 mt-md-0"> &ndash;&gt;-->
<!--                                    <div class="mt-3 mt-sm-8 mt-md-0">-->
<!--                                        <p class="h3 fontw-700 mb-2 montserrat">Republic of Moldova</p>-->
<!--                                        <p class="h6 mb-4 montserrat">Chisinau, str. Alexandru Hajdeu 122, MD 2005</p>-->
<!--                                        <a href="tel:+373 68 568 009" class="white&#45;&#45;text">-->
<!--                                            <p class="h5 fontw-700 d-flex align-center mb-2 montserrat">-->
<!--                                                <img :src="require('@/assets/icon/phone-call-white.svg')" class="mr-2" alt="">+373 68 568 009-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                        <a href="mailto:projects@gbmf.tech" class="white&#45;&#45;text">-->
<!--                                            <p class="h5 fontw-700 d-flex align-center montserrat">-->
<!--                                                <img :src="require('@/assets/icon/email-write-white.svg')" class="mr-2" alt="">projects@gbmf.tech-->
<!--                                            </p>-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </v-col>-->
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <get-started-block  ref="getstarted"/>
    </div>
</template>



<script >
import HeaderMenuLink from '@/components/base/HeaderMenuLink.vue'
import GetStartedBlock from '@/components/base/GetStartedBlock.vue'

export default {
    props: {
        dialog: {
            type: Boolean,
            default: false
        }
    },
    components:{
        HeaderMenuLink,
        GetStartedBlock,
    },
    name:"HeaderMenuBlock",
    data:()=>({
        hovered: false,
        popupbgimg: require('@/assets/img/burger/noimage.png'),
        popupbgimgdefault: require('@/assets/img/burger/noimage.png'),
        lang: 0,
        dropdown_lang:[
            {
                link: '',
                text: 'EN',
            },
            {
                link: '',
                text: 'DE',
            }
        ],
        headermenu:[

            {
                nr: '01',
                img: require('@/assets/img/burger/1.png'),
                text: 'ABOUT US',
                link: 'frontend.about',
            },
            {
                nr: '02',
                img: require('@/assets/img/burger/2.png'),
                text: 'Services',
                link: 'frontend.services',
            },
            {
                nr: '03',
                img: require('@/assets/img/burger/3.png'),
                text: 'Projects',
                link: 'frontend.works',
            },
            {
                nr: '04',
                img: require('@/assets/img/burger/4.png'),
                text: 'Vacancies',
                link: 'frontend.careers',
            },
            {
                nr: '05',
                img: require('@/assets/img/burger/5.png'),
                text: 'BLOG',
                // link: 'frontend.blog',
	            to: 'https://blog.gbmf.tech'
            },
            {
                nr: '06',
                img: require('@/assets/img/burger/6.png'),
                text: 'Let\'s talk',
                link: 'frontend.contacts',
            }
        ],
    }),
    methods: {
        closeBlock(){
            // this.dialog = false;
            // this.$destroy();
            this.$emit('closeDialog');
        },
        hoverbg(item){
            this.hovered = true;
            this.popupbgimg = item.img;
        },
        hoverbgimg(){
            this.hovered = false;
            setTimeout(() => this.closeHoverBg(), 600)
        },
        closeHoverBg(){
            if(this.hovered == false)
                this.popupbgimg = this.popupbgimgdefault;
        }
    }
}
</script>


<style lang="scss">
.bgimageburgermenu{
    position: absolute !important;
    right: 0;
    top: 0;
}
.logowidth{
    width: 159px;
    margin-top: 5px;
}
@media all and(max-width: 930px){
    .logowidth{
        width: 100px!important;
    }
}
.back{
    border: 1px solid #8eaefd;
}
.bordermenurectangle{
    border-radius: 50%;
    right: 0;
    top: 0;
    width: 1197.97px;
    height: 1153.44px;
    .bginborder{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
        border-radius: 50%;
        opacity: 0.3;
    }
    .bgmenublock-enter-active{
        animation: apearIn 0.5s;
    }
    .bgmenublock-leave-active{
        animation: apearOut 0.5s;


        @keyframes apearIn{
            from{
                opacity: 0;
            }
            to{
                opacity: 1;
            }
        }
        @keyframes apearOut{
            from{
                opacity: 0;
            }
            to{
                opacity: 1;
            }
        }
    }
}
.bottom0{
    bottom: 0;
}
.hoverbg{
    background-image: url('~@/assets/images/weareimg.png');
    background-repeat: no-repeat;
    background-size: contain;
}

@media all and(min-width: 930px){
    .headermenumainblock{
        height: 100%;
    }
}
</style>
