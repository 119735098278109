<template>
    <v-dialog class=""  v-model="dialoggetstarted" fullscreen hide-overlay style="border-radius:0px">
        <div class="bg-blue p-relative h-100 dialoggetstarted">
            <v-container fluid>
                <div class="d-flex justify-space-between justify-lg-end">
                    <v-container>
                        <div class="d-flex justify-space-between justify-lg-end">
<!--                            <div class="montserrat white&#45;&#45;text d-flex align-center mt-lg-10 mr-lg-16 white px-6 py-3 rounded-10">-->
<!--                                <span class="h5-6 mr-2 dark1">Your Discount: </span><span class="h4 bold dark1">{{ (e1 - 1) * 100}}</span><span class="h4 dark1">$</span>-->
<!--                            </div>-->
                        </div>
                    </v-container>
                    <v-btn text retain-focus-on-click class="white--text removebefore h5-6 montserrat pa-0 mt-5 mr-8" @click="changeDialogget" :ripple="false">
                        Close <v-icon class="ml-2">mdi-close</v-icon>
                     </v-btn>
                </div>
            </v-container>
            <v-container>
                <div class="mt-md-8 pt-md-8 pt-lg-16 p-relative">
                    <div class="my-md-8"></div>
                    <p class="h5-6 montserrat questnumber" v-show="e1 < 5" style="color:#8eaefd;">Question {{e1}}/4</p>
                        <v-stepper v-model="e1" class="bg-blue disable-box-shadow">
                            <v-stepper-items>
                                <v-stepper-content class="pa-0" step="1">
                                    <div class="montserrat white--text">
                                        <h1 class="h3 bold">How do you feel about briefs with complicated questions, where you have to think a lot and figure out the terms?</h1>
                                    </div>
                                    <v-form
                                        lazy-validation
                                        ref="form1"
                                        v-model="valid"
                                    >
                                        <v-radio-group class="getstartdialog pt-5" v-model="form.q1" required :rules="requiredRules">
                                            <v-radio active-class class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="I hate it"
                                                value="I hate it"
                                                @click="message = 'We understand you! This is why we can record your answers via phone!'"
                                                >
                                            </v-radio>
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="I love to meddle with it"
                                                value="I love to meddle with it"
                                                @click="message = 'Cool! We are going to send you a pretty and interesting brief that will help you find out a lot of new things about your company'"
                                                >
                                            </v-radio>
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="I understand that they are necessary"
                                                value="I understand that they are necessary"
                                                @click="message = 'Yes, they are important, but we can find out everything important from you on the phone or at a meeting to give it a head start'"
                                                >
                                            </v-radio>
                                        </v-radio-group>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content class="pa-0" step="2">
                                    <div class="montserrat white--text">
                                        <h1 class="h3 bold">What do you consider to be the most important thing in the final product?</h1>
                                    </div>
                                    <v-form
                                        lazy-validation
                                        ref="form2"
                                        v-model="valid"
                                    >
                                        <v-radio-group class="getstartdialog pt-5" v-model="form.q2" required :rules="requiredRules">
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="Usability (comfort)"
                                                value="Usability (comfort)"
                                                @click="message = 'Who are we trying to trick? Of course, we understand that it has to meet all the parameters'"
                                                >
                                            </v-radio>
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="Unique design and creative approach"
                                                value="Unique design and creative approach"
                                                @click="message = 'Who are we trying to trick? Of course, we understand that it has to meet all the parameters'"
                                                >
                                            </v-radio>
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="High conversion level"
                                                value="High conversion level"
                                                @click="message = 'Who are we trying to trick? Of course, we understand that it has to meet all the parameters'"
                                                >
                                            </v-radio>
                                        </v-radio-group>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content class="pa-0" step="3">
                                    <div class="montserrat white--text">
                                        <h1 class="h3 bold">What is the most important thing for you when working with an agency?</h1>
                                    </div>
                                    <v-form
                                        lazy-validation
                                        ref="form3"
                                        v-model="valid"
                                    >
                                        <v-radio-group class="getstartdialog pt-5" v-model="form.q3" required :rules="requiredRules">
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="Possibility for quick communication"
                                                value="Possibility for quick communication"
                                                @click="message = 'Yes, there are quite a few introverts among us, but we are always there when it comes to your projects, available to communicate with you through any channel you prefer!'"
                                                >
                                            </v-radio>
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="Approval processes for each stage of work"
                                                value="Approval processes for each stage of work"
                                                @click="message = 'We are glad that you chose this point because we are one team!'"
                                                >
                                            </v-radio>
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="Maintenance and technical support upon project delivery"
                                                value="Maintenance and technical support upon project delivery"
                                                @click="message = 'We are not that type of guys that disappear into a black hole as soon as the project is delivered – we are always there for you.'"
                                                >
                                            </v-radio>
                                        </v-radio-group>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content class="pa-0" step="4">
                                    <div class="montserrat white--text">
                                        <h1 class="h3 bold">What do you look for in a developer team?</h1>
                                    </div>
                                    <v-form
                                        lazy-validation
                                        ref="form4"
                                        v-model="valid"
                                    >
                                        <v-radio-group class="getstartdialog pt-5" v-model="form.q4" required :rules="requiredRules">
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="Competency"
                                                value="Competency"
                                                @click="message = 'Everything is important! And what’s more important is that we have everything!'"
                                                >
                                            </v-radio>
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="Responsibility"
                                                value="Responsibility"
                                                @click="message = 'Everything is important! And what’s more important is that we have everything!'"
                                                >
                                            </v-radio>
                                            <v-radio class="py-2"
                                                :ripple="false"
                                                color="white"
                                                label="Ease of communication"
                                                value="Ease of communication"
                                                @click="message = 'Everything is important! And what’s more important is that we have everything!'"
                                                >
                                            </v-radio>
                                        </v-radio-group>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content class="pa-0" step="5">
                                    <div class="montserrat white--text">
                                        <h1 class="h3 bold">And last, but not least: Leave your phone number so we can call you</h1>
                                    </div>
                                    <v-form
                                        lazy-validation
                                        ref="form5"
                                        v-model="valid"
                                    >
                                        <div class="my-10">
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                        color="white"
                                                        v-model="form.name"
                                                        label="Name"
                                                        :rules="requiredRules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                        color="white"
                                                        v-model="form.phone"
                                                        label="Phone Number"
                                                        :rules="phoneRules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field
                                                        color="white"
                                                        v-model="form.email"
                                                        label="Email"
                                                        :rules="emailRules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-form>
                                </v-stepper-content>
                                <v-stepper-content class="pa-0" step="6">
                                    <div class="d-flex justify-center">
                                        <img class="" :src="require('@/assets/icon/emoji1.svg')" alt="">
                                    </div>
                                    <div class="d-flex justify-center">
                                        <img class="" :src="require('@/assets/icon/Itmatch.svg')" alt="" style="max-width: 100%;">
                                    </div>
                                    <v-row class="mt-10">
                                        <v-col cols="12" lg="6" offset-lg="3">
                                            <div class="h4 text-center white--text montserrat">
                                                It seems that we were made for each other, which means that the result of our joint work will surpass any and all of your expectations.
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div class="d-flex justify-center my-13">
                                        <v-btn
                                        @click="dialoggetstarted = false; e1 = 1"
                                        class="white removebefore blue1 px-16 py-7 px-10 btn-border-radius"
                                        >Continue</v-btn>
                                    </div>
                                    <div class="d-flex justify-center">
                                        <img class="d-none d-md-block" :src="require('@/assets/icon/gsdot.svg')">
                                    </div>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-form>
                    <div v-show="e1 < 6" class="mt-sm-7">
                        <v-btn
                             @click="e1 -= 1"
                             v-show="e1 > 1"
                             class="bg-blue removebefore white--text px-16 py-7 px-10 btn-border-radius back disable-box-shadow mr-5 mb-2"
                        >Back</v-btn>
                        <v-btn
                             @click="next()"
                             class="white removebefore blue1 px-16 py-7 px-10 btn-border-radius mb-2"
                        >Next</v-btn>
                    </div>
                    <div class="p-absolute d-none d-lg-block r-0" v-if="e1 < 6">
                        <img class="d-none d-md-block" :src="require('@/assets/icon/gsdot.svg')">
                    </div>
                    <div v-show="message" class="mt-md-16">
                        <v-row>
                            <v-col cols="12" md="8">
                                <div class="bordertriangle"></div>
                                <div class="d-flex align-center bordermessage">
                                    <span class="ma-3"> <img :src="require('@/assets/icon/emojimes.svg')" alt=""> </span>
                                    <div class="h5 ma-3 ml-0 white--text montserrat" v-html="message"></div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4">

                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-container>
        </div>
    </v-dialog>
</template>

<script>
import ValidationMixin from '@/mixins/validationMixin.js';

export default {
    name: 'GetStartedBlock',
    mixins: [
        ValidationMixin
    ],
    data:()=>({
        message: false,
        e1: 1,
        dialoggetstarted: false,
        valid: false,
        form: {
            name: '',
            phone: '',
        },
    }),
    methods:{
        changeDialogget(){
            this.dialoggetstarted = !this.dialoggetstarted
        },
        endgetstarted(){
            this.e1 = 6;
            setTimeout(()=>{
                this.dialoggetstarted = false;
            },4000)
            setTimeout(()=>{
                this.e1 = 1;

            },6000)

        },
        next(){
            this.message = false;
            var tmp = 'form' + this.e1;
            if(this.$refs[tmp].validate()){
                if(this.e1 == 5){
                    this.sendForm();
                }
                this.e1 += 1;
            }
        },
        sendForm(){
            this.axios.post(process.env.VUE_APP_API + 'project', this.form).then(response => {
                this.$refs.form.reset();
            });
        }
    }
}
</script>

<style lang="scss">

.dialoggetstarted{
    overflow: auto;
    .h72{
        font-size: 72px;
        font-family: Kalam !important;
    }
    label.v-label{
        color: white !important;
        font-size: 18px !important;
        font-family: 'Montserrat';
    }
    input{
        color: white !important;
    }
    div.v-text-field > .v-input__control > .v-input__slot::before{
        border-color: rgba(255, 255, 255, 0.42) !important;
        bottom: -8px;
    }
    div.v-text-field > .v-input__control > .v-input__slot::after{
        bottom: -8px;
    }
    @media all and (min-width: 930px){
        div.v-text-field > .v-input__control > .v-input__slot:hover{
            border-color: rgba(255, 255, 255, 0.42) !important;
        }
    }
    .bordermessage{
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
    }
    .bordertriangle{
        border: 18px solid transparent !important;
        border-bottom: 17px solid white !important;
        margin-left: 2rem;
        width: 18px;
    }
    .back{
        border: 1px solid #8eaefd;
    }
    div i::before{
        color: white !important;
    }
    .bg-discount{
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 5px #38a4dc;
    }
    .getstartedadditionaltext{
        border: 1px solid #FFFFFF;
    }
    @media all and (max-width: 660px){
        label.v-label{
            font-size: 14px !important;
        }
    }
    @media all and (min-width: 660px){
        .questnumber{
            font-size: 18px !important;
        }
    }

}
</style>
