function page(path){
    return () => import('../views/' + path).then(m => m.default || m)
}

export default [
    {
        path: '/',
        name: 'frontend.home',
        component: page('homepage.vue'),
        meta: {
            title: 'GBMF Tech | A high-performance IT development company',
        },
    },
    {
        path: '/about',
        name: 'frontend.about',
        component: page('about.vue'),
        meta: {
            title: 'About us: find out how GBMF stands out among many great IT companies',
        },
    },
    {
        path: '/careers',
        name: 'frontend.careers',
        component: page('careers.vue'),
        meta: {
            title: 'Vacancies at GBMF: become part of a success-driven team',
        },
    },
    {
        path: '/services',
        name: 'frontend.services',
        component: page('services.vue'),
        meta: {
            title: 'GBMF Services | A vast range of IT products for your business',
        },
    },
    {
        path: '/works',
        name: 'frontend.works',
        component: page('works.vue'),
        meta: {
            title: 'GBMF Projects - web apps, aggregators, and mobile apps for various industries',
        },
    },
    {
        path: '/work/:slug',
        name: 'frontend.work',
        component: page('work.vue'),
        props: true
    },
    {
        path: '/blog',
        name: 'frontend.blog',
        component: page('blog.vue'),
        meta: {
            title: 'GBMF Blog: be constantly in touch with the IT world',
        },
    },
    {
        path: '/post/:slug',
        name: 'frontend.post',
        component: page('post.vue'),
        props: true
    },
    {
        path: '/technologies',
        name: 'frontend.technologies',
        component: page('technologies.vue'),
    },
    {
        path: '/contacts',
        name: 'frontend.contacts',
        component: page('contacts.vue'),
        meta: {
            title: 'Contacts - GBMF | Get in touch with the unique IT team',
        },
    },
    {
        path: '/terms',
        name: 'frontend.terms',
        component: page('terms.vue'),
        meta: {
            title: 'Terms and Conditions | GBMF',
        },
    },
    {
        path: '/privacy',
        name: 'frontend.privacy',
        component: page('privacy.vue'),
        meta: {
            title: 'Privacy Policy | GBMF',
        },
    },
    {
        path: '*',
        name: 'pagenotfound',
        component: page('404'),
        meta: {
            title: 'Page not Found | GBMF'
        }
    }
]
