<template>
    <v-app :class="{'bg-lighten': this.$route.name == 'frontend.contacts'}">
        <transition name="fade" mode="out-in" appear @enter="disablePreloader">
            <preloader v-if="preloader"/>
        </transition>
        <transition name="fade" mode="out-in" appear @enter="disablePreloader">
            <div v-if="!preloader">
                <div>
                    <header-menu :class="classheader" :ver="classheader"></header-menu>
                    <div class="main">
                        <router-view @headerClass="classHeader($event)" :style="preloader ? 'height: 0%' : ''"/>
                    </div>
                    <footer-menu></footer-menu>
                </div>
            </div>
        </transition>
    </v-app>
</template>

<script>
import HeaderMenu from '@/components/layouts/header.vue'
import FooterMenu from '@/components/layouts/footer.vue'
import Preloader from '@/components/base/Preloader.vue'

export default {
    name: 'App',
    components: {
        HeaderMenu,
        FooterMenu,
        Preloader,
    },
    data:() => ({
        preloader: false,
        classheader: '',
    }),
    watch: {
        $route() {
            window.scrollTo(0, 0);
        }
    },
    mounted(){
        this.stored();
        window.scrollTo(0, 0);
    },
    methods:{
        disablePreloader(){
            setTimeout(() => this.preloader = false, 4500)
        },
        classHeader(val){
            this.classheader = val;
        },
        stored(){
            this.axios.get(process.env.VUE_APP_API + 'server/location').then(response => {
                this.$store.commit('setLocation', response.data);
            })
        },
    }
};
</script>
<style lang="scss">
.fade-enter-active, .fade-leave-active{
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter, .fade-leave-active{
    transition-duration: 0.45s;
    opacity: 0;
}
.section-enter-active{
  animation: slideIn 0.3s;
}
.section-leave-active{
  animation: slideOut 0.3s;
}

@keyframes slideIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes slideOut{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}
</style>
