<template>
    <div class="headermenustable p-relative">
        <div :class="{'headerwhitespace': fixed}" ></div>
        <v-container v-on="handleScroll()" fluid class="t-0 transition03s" :class="{'headerfixed p-fixed': fixed, 'scrolledheader': scrolled}" ref="headerfixed">
            <header class="d-flex justify-space-between align-center py-sm-5 montserrat px-md-4">
                <router-link :to="{ name: 'frontend.home', params: {} }">
                    <img v-if="ver == ''" class="logowidth" :src="require('@/assets/logo/logo-blue.svg')" alt="Logo">
                    <img v-if="ver != ''" class="logowidth" :src="require('@/assets/logo/logo-white.svg')" alt="Logo">
                </router-link>

                <div class="d-flex align-center">
                    <router-link
                        :to="{ name: item.link, params: {} }"
                        class="text-decoration-none px-2 uppercase montserrat fontw-600 text-sm mx-2 d-none d-lg-flex menuitem"
                        v-for="(item,i) in headermenu"
                        :key="i"
                        v-html="item.text"></router-link>
                    <div class="d-none d-lg-flex mx-5">
                        <img v-if="ver == ''" :src="require('@/assets/icon/divider.svg')" disabled>
                        <img v-if="ver != ''" :src="require('@/assets/icon/dividerwhite.svg')" disabled>
                    </div>
                    <div class="d-none d-sm-block" @click="$refs.getstarted.changeDialogget()">
                        <button-standard-large large="true" outlined btnclass="btn-border-radius border-white-01 removebefore py-6 px-10 bold">GET STARTED</button-standard-large>
                    </div>
                    <div class="d-none d-sm-flex mx-5">
                        <img v-if="ver == ''" :src="require('@/assets/icon/divider.svg')" disabled>
                        <img v-if="ver != ''" :src="require('@/assets/icon/dividerwhite.svg')" disabled>
                    </div>
                    <div class="d-flex align-center">
                        <v-dialog v-model="dialog" fullscreen hide-overlay transition="slide-x-reverse-transition" content-class="nonrounded bg-blue" origin="top right 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="removebefore uppercase h6 fontw-700 pa-0" depressed retain-focus-on-click v-bind="attrs" v-on="on" @click="changeDialog" :ripple="false" color="transparent">
                                    Menu
                                    <img v-if="ver == ''" class="ml-4" :src="require('@/assets/icon/menu.svg')">
                                    <img v-if="ver != ''" class="ml-4" :src="require('@/assets/icon/menuwhite.svg')">
                                </v-btn>
                            </template>
                            <header-menu-block :dialog="dialog" @closeDialog="changeDialog"></header-menu-block>
                        </v-dialog>
                    </div>
                </div>
                <get-started-block  ref="getstarted"/>
            </header>
        </v-container>
    </div>
</template>

<script>
import HeaderMenuBlock from '@/components/base/HeaderMenuBlock.vue'
import GetStartedBlock from '@/components/base/GetStartedBlock.vue'

export default {
    name: 'HeaderMenu',
    components:{
        HeaderMenuBlock,
        GetStartedBlock
    },
    props: ['ver'],
    data:()=>({
        height: 0,
        dialog: false,
        fixed: false,
        animationTrigger: true,
        headermenu:[
            {
                text: 'About us',
                link: 'frontend.about',
            },
            {
                text: 'Services',
                link: 'frontend.services',
            },
            {
                text: 'Projects',
                link: 'frontend.works',
            },
            {
                text: 'Let\'s talk',
                link: 'frontend.contacts',
            },
            // {
            //     text: 'Contacts',
            //     link: 'frontend.contacts',
            // }
        ],
        limitPosition: 800,
        scrolled: false,
        lastPosition: 0
    }),
    watch:{
       '$route': 'closeDialog'
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        changeDialog(){
            this.dialog = !this.dialog
        },
        closeDialog(){
            this.dialog = false
        },
        handleScroll() {
            if(this.limitPosition < window.scrollY){
                this.fixed = true;
            }
            if(this.limitPosition > window.scrollY){
                this.fixed = false;
                this.scrolled = false;

            }
            if (this.lastPosition > window.scrollY) {
                this.scrolled = true;
            }

            if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
                this.scrolled = false;
            }

            this.lastPosition = window.scrollY;
        }
    }
}
</script>
<style lang="scss">
.v-application{
    .headermenustable{
        .transition03s{
            transition: 0.3s;

        }
        .scrolledheader{
            height: auto!important;
            opacity: 1!important;
        }
        .border-white-01{
            border: 1px solid rgba(50, 50, 50, 0.1);
        }
        .headerfixed{
            background: #FFF;
            height: 0px;
            opacity: 0;
        }
        z-index: 100;
        // background: #FFF;
        .v-list-item__title, .v-btn__content, a{
            color: #323232;
        }
        .logowidth{
            width: 159px;
            margin-top: 5px;
        }
        @media all and (min-width: 930px){
            .menuitem:hover{
                opacity: 0.6;
            }
        }
        .menuitem.router-link-exact-active{
            opacity: 0.6;
        }
    }
}
.headermenustable.headerblue{
    .border-white-01{
        border: 1px solid rgba(255, 255, 255, 0.1);
    }
    .headerfixed{
        background: #1E5CFB;
    }
    background: #1E5CFB;
    .theme--light.v-list{
        background: transparent;
    }
    .v-list-item__title, .v-btn__content, a{
        color: #fff;
    }
}
.v-dialog.nonrounded{
    border-radius: 0 !important;
}
@media all and (max-width: 1500px){
    .headercentermenu{
        display: none !important;
    }
}
.headermenustable .v-list-item__title{
    font-size: 14px;
}
.headertransparentblue.headertransparentblue{
    .v-list{
        background: transparent;
    }
    .v-list-item__title, .v-btn__content, a{
        color: #fff;
    }
    .headerfixed{
        background: #1E5CFB;
    }
}
@media all and (max-width: 660px){
    .headerwhitespace{
        height: 60px;
    }
}
@media all and (min-width: 660px){
    .headerwhitespace{
        height: 114px;
    }
}
</style>
