<template>
    <div class="bordertopop02 relative">
        <div v-if="!$cookies.get('cookies_accepted')" v-show="cookies_block" class="cookies">
            <div class="zi-2 text-md-center bg-blue white--text p-fixed h6-7 b-0 w-100 py-4 d-lg-flex align-center justify-center pa-sm-4">
                We use cookies on this site to improve performance. <br class="d-sm-none"/>By browsing this site you are agreeing to this. For more information see our
                <router-link class="dark1 h6-7 text-decoration-underline montserrat white--text ml-1" :to="{ name: 'frontend.privacy', params: {} }">Privacy Policy</router-link>
                <img :src="require('@/assets/img/cookie.png')" class="d-none d-lg-inline-block ml-2" alt="">
                <v-btn
                @click="acceptcookie()"
                class="white removebefore blue1 px-md-8 py-md-2 px-3 btn-border-radius mt-3 mt-md-0 d-block d-md-inline-block ml-md-3"
                >Accept</v-btn>
                <img :src="require('@/assets/img/cookie-mobile.png')" class="d-lg-none" style="position: absolute;right: 150px;bottom: 0;" alt="">
            </div>
        </div>
        <v-container class="my-8" id="footerspace">
            <div class="d-flex flex-column flex-lg-row justify-space-between align-center">
                <div class="">
                    <router-link :to="{ name: 'frontend.home', params: {} }"><img :src="require('@/assets/logo/logo-blue.svg')" alt="Logo" width="172"></router-link>
                </div>
                <div class="">
                    <v-list flat class="bg-transparent">
                        <v-list-item-group class="d-flex flex-column flex-lg-row ">
                            <v-list-item class="px-0 text-lg-left text-center" v-for="(item,i) in footermenu" :key="i" :ripple="false">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <router-link class="h6 text-decoration-none dark1 bold mx-7 montserrat" :to="{ name: item.link, params: {} }">{{item.text}}</router-link>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
            </div>
            <div class="">
                <div class="d-flex justify-center justify-lg-end flex-row mt-5 mb-12 iconstosoccial">
                    <a href="https://twitter.com/GbmfGroup" target="_blank" class="d-flex justify-center mx-2"><img :src="require('@/assets/icon/twitter.svg')" width="18"></a>
                    <a href="https://blog.gbmf.tech" target="_blank" class="d-flex justify-center mx-2"><img :src="require('@/assets/icon/medium.svg')" width="18"></a>
                    <a href="https://www.linkedin.com/company/gbmf-group" target="_blank" class="d-flex justify-center mx-2"><img :src="require('@/assets/icon/linkedin.svg')" width="17"></a>
                </div>
            </div>
            <div class="d-flex flex-column flex-lg-row justify-center justify-lg-space-between align-center align-lg-end">
                <div class="">
                    <p class="h6 mb-0 montserrat">Copyright ©  {{ new Date().getFullYear() }} GBMF - All Rights Reserved.</p>
                </div>
                <div class="d-block text-right">
                    <!-- <router-link class="dark1 h6 text-decoration-underline montserrat mr-4" :to="{ name: 'frontend.privacy', params: {} }">Privacy Policy</router-link> -->
					<div class="">

						<a href="https://clutch.co/profile/gbmf-tech" class="d-flex align-center mt-6 mt-lg-0">
							<span class="mr-2">
								<svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" viewBox="0 0 19.5 22" width="14px">
									<path fill="#14111D" d="M15.2 15.6c-1.1 1-2.6 1.6-4.2 1.6-3.6 0-6.2-2.6-6.2-6.2S7.4 4.9 11 4.9c1.6 0 3.1.5 4.2 1.5l.8.7 3.4-3.4-.9-.8C16.6 1 13.9 0 11 0 4.6 0 0 4.6 0 10.9 0 17.2 4.7 22 11 22c2.9 0 5.6-1 7.6-2.9l.8-.8-3.4-3.4-.8.7z"></path>
									<circle fill="#FF3D2E" cx="10.8" cy="11" r="3.7"></circle>
								</svg>
							</span>
							<span class="mb-1 black--text smtextsizesmall">Check out our ★★★★★ reviews on Clutch.</span>
						</a>
					</div>
                    <router-link class="dark1 h6 text-decoration-underline montserrat d-lg-block d-none" :to="{ name: 'frontend.terms', params: {} }">Terms and Conditions</router-link>
                </div>
            </div>
            <!-- <div class="d-flex flex-column">
                <div class="d-flex justify-center">
                    <router-link :to="{ name: 'frontend.home', params: {} }"><img :src="require('@/assets/icon/gbmflogo.svg')" alt="Logo" width="172"></router-link>
                </div>
                <div class="d-flex justify-center flex-column my-14">
                    <div class="d-flex justify-center py-4" v-for="(item,i) in footermenu" :key="i">
                        <router-link class="h6 montserrat text-decoration-none dark1 bold" :to="{ name: 'frontend.home', params: {} }">{{item.text}}</router-link>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <a class="h5 text-center montserrat blue1 bold" href="mailto:info@gbmf-studio.com">info@gbmf-studio.com</a>
                    <p class="h6 text-center montserrat mt-12">London,1st Floor, 2 Woodberry Grove Finchley N12 0DR</p>
                    <a class="h5 text-center montserrat blue1 bold" href="tel:+44 741-832-3492">+44 741-832-3492</a>
                    <p class="h6 text-center montserrat mt-12">Citrus Heights,California 95610, Sylvan Meadow Court 6830</p>
                    <a class="h5 text-center montserrat blue1 bold" href="tel:+1 916-272-5825">+1 916-272-5825</a>
                </div>
                <div class="d-flex justify-center flex-row my-10">
                    <a href="https://facebook.com/GBMFStudio/" class="pr-5"><img :src="require('@/assets/icon/facebook.svg')"></a>
                    <a href="https://instagram.com/gbmf_studio?igshid=uvki6gvgcukh" class="pr-5"><img :src="require('@/assets/icon/instagram.svg')"></a>
                    <a href="https://www.linkedin.com/company/gbmf-studio" class="pr-5"><img :src="require('@/assets/icon/linkedin.svg')"></a>
                    <a href="https://twitter.com/GbmfStudio" class="pr-5"><img :src="require('@/assets/icon/twitter.svg')"></a>
                    <a href="https://www.youtube.com/channel/UCRC4oadrjql0MjmYAsTPYwQ" class="pr-5"><img :src="require('@/assets/icon/youtube.svg')"></a>
                </div>
                <div class="d-flex justify-center">
                    <p class="h6 montserrat">© Copyright {{ new Date().getFullYear() }}. All rights reserved</p>
                </div>
            </div>
        </v-container>
        <v-container class="mt-16 mb-8 d-none d-md-block">
            <div class="">
                <v-row>
                    <v-col class="" cols="5">
                        <div class="pb-3 pb-md-1">
                            <router-link :to="{ name: 'frontend.home', params: {} }"><img :src="require('@/assets/icon/gbmflogo.svg')" alt="Logo" width="172"></router-link>
                        </div>
                        <div class="py-16">
                            <a href="https://facebook.com/GBMFStudio/" class="pr-3"><img :src="require('@/assets/icon/facebook.svg')"></a>
                            <a href="https://instagram.com/gbmf_studio?igshid=uvki6gvgcukh" class="pr-3"><img :src="require('@/assets/icon/instagram.svg')"></a>
                            <a href="https://www.linkedin.com/company/gbmf-studio" class="pr-3"><img :src="require('@/assets/icon/linkedin.svg')"></a>
                            <a href="https://twitter.com/GbmfStudio" class="pr-3"><img :src="require('@/assets/icon/twitter.svg')"></a>
                            <a href="https://www.youtube.com/channel/UCRC4oadrjql0MjmYAsTPYwQ" class="pr-3"><img :src="require('@/assets/icon/youtube.svg')"></a>
                        </div>
                        <div class="pt-3 pt-md-0">
                            <p class="h6 montserrat">© Copyright {{ new Date().getFullYear() }}. All rights reserved</p>
                        </div>
                    </v-col>
                    <v-col cols="7">
                        <div class="">
                            <a class="h5 montserrat blue1 bold" href="mailto:info@gbmf-studio.com">info@gbmf-studio.com</a>
                            <v-row class="mt-10">
                                <v-col cols="12" md="5">
                                    <p class="h6 montserrat">London,1st Floor, 2 Woodberry Grove Finchley N12 0DR</p>
                                    <a class="h5 montserrat blue1 bold" href="tel:+44 741-832-3492">+44 741-832-3492</a>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <p class="h6 montserrat">Citrus Heights,California 95610, Sylvan Meadow Court 6830</p>
                                    <a class="h5 montserrat blue1 bold" href="tel:+1 916-272-5825">+1 916-272-5825</a>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="">
                            <v-list flat class="mt-5">
                                <v-list-item-group class="d-flex">
                                    <v-list-item class="px-0" v-for="(item,i) in footermenu" :key="i" :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <router-link class="h6 montserrat text-decoration-none dark1 bold" :to="{ name: 'frontend.home', params: {} }">{{item.text}}</router-link>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </div>
                    </v-col>
                </v-row>
            </div> -->
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import postscribe from 'postscribe';
Vue.use(VueCookies)

export default {
    name: 'Footer',
    data:()=>({
        cookies_block: true,
        footermenu:[
            {
                text: 'About Us',
                link: 'frontend.about',
            },
            {
                text: 'Services',
                link: 'frontend.services',
            },
            {
                text: 'Projects',
                link: 'frontend.works',
            },
            {
                text: 'Let\'s talk',
                link: 'frontend.contacts',
            }
        ],
    }),
    mounted(){
            postscribe('#footerspace', `<script>
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/5e9497f669e9320caac321d1/default';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();
            <\/script>`)
    },
    methods: {
        acceptcookie(){
            this.$cookies.set("cookies_accepted", true, 60 * 60 * 24 * 365);
            this.cookies_block = false;
        }
    }
}
</script>
<style lang="scss">
bordertopop02{
	z-index: 10;
}
.iconstosoccial{
    a{
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #c2c2c2;
    }
    @media all and (min-width: 930px){
        a:hover{
            background-color: #1E5CFB;
        }
    }
}
@media all and (max-width: 400px){
    .smtextsizesmall{
        font-size: 13px;
    }
}
</style>
