<template>
    <v-btn class="uppercase bold montserrat"
        :class="btnclass + allowHover"
        :ripple="false"
        :x-large="xlarge"
        :large="large"
        :small="small"
        :x-small="xsmall"
        :outlined="outlined"
        depressed
    >
        <slot/>
    </v-btn>
</template>

<script>
export default {
    name:"ButtonStandardLarge",
    props: ['btnclass', 'hoverable'],
    data:()=>({
        xlarge: false,
        large: false,
        small: false,
        xsmall: false,
        outlined: false,
        allowHover: '',
    }),
    mounted(){
        this.buttonConfig()
    },
    methods:{
        buttonConfig(){
            if (this.$attrs.large)
                this.large = true;
            if (this.$attrs.xlarge)
                this.xlarge = true;
            if (this.$attrs.small)
                this.small = true;
            if (this.$attrs.xsmall)
                this.xsmall = true;
            if(typeof this.$attrs.outlined !== 'undefined')
                this.outlined = true;
            if (this.hoverable)
                this.allowHover = ' hoverable';
        }
    }
}
</script>

<style lang="scss">

</style>
