import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
// import VuePreloaders from 'vue-preloaders'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.use(VueRouter)
// Vue.use(VuePreloaders, /*{ options }*/)


const router = createRouter();

export default router

function createRouter(){
    const router = new VueRouter({
      mode: 'history',
      base: process.env.BASE_URL,
      routes
    })

    router.beforeEach(beforeEach)

    return router
}

async function beforeEach (to, from, next) {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(nearestWithMeta){
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });

            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        }).forEach(tag => document.head.appendChild(tag));
    }

    return next();
}
