<template>
    <v-btn color="transparent" class="btn-circle montserrat px-8 bold" :ripple="false" depressed>
        <slot/>
    </v-btn>
</template>

<script>
export default {
    name:"ButtonCircle",
}
</script>

<style lang="scss">
@import "@/sass/variables.scss";
.btn-circle::before{
    content: " ";
    font-family: montserrat !important;
    width: 60px !important;
    height: 60px !important;
    opacity: 1 !important;
    border-radius: 60px !important;
    background-color: $blue1 !important;
    transition: 0.2s !important;
    margin-top: -11px !important;
}
@media all and (min-width: 930px){
    .btn-circle:hover:before{
        background-color: $blue1 !important;
        width: 100% !important;
        opacity: 1 !important;
        border-radius: 60px !important;
    }
    .btn-circle:hover{
        font-family: montserrat !important;
        font-weight: bold !important;
        font-size:  $h6 !important;
        color: $white !important;
        text-transform: uppercase !important;
    }
}
</style>
