<template>
    <div class="post">
        <div class="main w-100">
            <div class="bgimage" :style="`background: linear-gradient(rgba(30, 92, 251, 0.7) 100%, rgba(30, 92, 251, 0.7) 100%), url(` + getImage() + `) no-repeat scroll center;`"></div>
            <v-container class="py-16 py-sm-0 mb-sm-8 titleblog mt-lg-16 pt-lg-4 titlecontainer" ref="titleBox">
                <div class="">
                    <router-link class="h6 fontw-700 white--text montserrat d-flex align-center mb-6 mb-sm-1" :to="{ name: 'frontend.blog' }"><v-icon class="white--text mr-5" small>mdi-chevron-left</v-icon>Back to all articles</router-link>
                    <div class="h2 white--text fontw-700" v-html="item.title"></div>
                </div>
            </v-container>
        </div>
        <div class="article bg-white pt-16">
            <v-container>
                <wait-loading v-if="loading"/>
                <v-row>
                    <v-col cols="12" lg="9" v-html="item.description"></v-col>
                    <v-col cols="12" lg="3">
                        <!-- <div class="iconstosoccial d-flex flex-md-column"> -->
                            <!-- <div class="mb-3">
                                <img :src="require('@/assets/icon/eye-dark.svg')" alt="" class="mr-3"> <span>118,2K</span>
                            </div>
                            <div class="mb-6">
                                <img :src="require('@/assets/icon/core-dark.svg')" alt="" class="mr-3"> <span>25,4K</span>
                            </div> -->
                            <!-- <div class="mb-3 mx-2">
                                <ShareNetwork
                                    v-if="item.title"
                                    network="facebook"
                                    :url="getCurrentUrl()"
                                    :title="item.title"
                                >
                                    <v-hover v-slot="{ hover }">
                                        <div class="iconmodule d-flex justify-center align-center">
                                            <img v-show="!hover" :src="require('@/assets/icon/facebook-dark.svg')" alt="">
                                            <img v-show="hover" :src="require('@/assets/icon/facebook.svg')" alt="">
                                        </div>
                                    </v-hover>
                                </ShareNetwork>
                            </div>
                            <div class="mb-3 mx-2">
                                <v-hover v-slot="{ hover }">
                                    <div class="iconmodule d-flex justify-center align-center">
                                        <img v-show="!hover" :src="require('@/assets/icon/email-write.svg')" alt="">
                                        <img v-show="hover" :src="require('@/assets/icon/email-write-white.svg')" alt="">
                                    </div>
                                </v-hover>
                            </div>
                            <div class="mb-3 mx-2">
                                <v-hover v-slot="{ hover }">
                                    <div class="iconmodule d-flex justify-center align-center">
                                        <img v-show="!hover" :src="require('@/assets/icon/link.svg')" alt="">
                                        <img v-show="hover" :src="require('@/assets/icon/link-white.svg')" alt="">
                                    </div>
                                </v-hover>
                            </div>
                        </div> -->
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="">
            <v-container class="my-16">
                <blog-block :items="others"/>
            </v-container>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import BlogBlock from '@/components/base/BlogBlock.vue'
import WaitLoading from '@/components/base/WaitLoading.vue'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

export default {
    metaInfo() {
        return {
            title: this.item ? this.item.title + ' | GBMF' : 'Blog Article | GBMF',
        };
    },
    components: {
        BlogBlock,
        WaitLoading
    },
    data: () => ({
        item: false,
        others: [],
        bg_height: 0,
        loading: true,
    }),
    watch: {
        '$route.params.slug'(newSlug, oldSlug) {
            this.getPost();
        }
    },
    created() {
        this.$emit('headerClass', 'headertransparentblue');
    },
    mounted(){
        if(!this.$route.params.slug){
            this.$router.push({name: 'frontend.blog'});
        }
        this.getPost();
        this.bg_height = this.$refs.titleBox.offsetTop + this.$refs.titleBox.offsetHeight;
        window.scrollTo(0, 0);
    },
    methods: {
        async getPost(){
            await this.axios.get(process.env.VUE_APP_API + 'post/' + this.$route.params.slug).then(response => {
                this.item = response.data.main;
                this.others = response.data.others;
                this.loading = false;
            })
        },
        getImage(){
            return process.env.VUE_APP_BASE + '/storage/' + this.item.image;
        },
        getHeight(){
            // return this.$refs.title.offsetTop + this.$refs.title.offsetHeight

        },
        getCurrentUrl(){
            return 'https://quirinnull.ga/post/' + this.$route.params.slug;
            // process.env.VUE_DOMAIN_NAME +
        }
    }

}
</script>

<style lang="scss">
.post{
    .bgimage{
        position: absolute;
        width: 100%;
        height: 500px;
        background-repeat: no-repeat;
        background-size: cover!important;
        background-position: center!important;
        top: 0
    }
    .titlecontainer{
        height: 268px;
    }
    .titleblog{
        z-index: 1;
        position: relative;
    }
    .article{
        position: relative;
        h2{
            margin-bottom: 15px;
        }
    }
    .iconmodule{
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background-color: #f5f5f5;
    }
    @media all and (min-width: 930px){
        .iconmodule:hover{
            background-color: #1E5CFB;
        }
    }
    .article{
        p{
            color: #707070;
        }
    }
}
</style>
