<template>
    <div class="d-flex" @mouseover="hovernr(item)" @mouseleave="hovernrleave(item)">
        <span v-show="$vuetify.breakpoint.xl && nr" class="pr-7 pt-5 h6 montserrat bold white--text mt-0">{{item.nr}}</span>
        <span>
            <router-link v-if="item.link" class="h2 montserrat text-decoration-none white--text uppercase fontw-800" @click="$emit('routed')" :to="{ name: item.link, params: {} }">{{item.text}}</router-link>
            <a v-if="item.to" target="_blank" class="h2 montserrat text-decoration-none white--text uppercase fontw-800" @click="$emit('routed')" :href="item.to">{{item.text}}</a>
        </span>
    </div>

</template>



<script >
export default {
    name:"HeaderMenuLink",
    props:[
        'item',
    ],
    data:()=>({
        nr: false,
    }),
    methods:{
        hovernr: function(item){
            this.nr = true;
        },
        hovernrleave: function(item){
            this.nr = false;
        },
    }
}
</script>


<style lang="scss">
.slowapear{
    -webkit-animation: slow 0.4s linear;
            animation: slow 0.4s linear;
}
@-webkit-keyframes slow{
    0%{
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
        -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
                width: 0%;
    }
    100%{
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
                width: 43.9px;
    }
}

@keyframes slow{
    0%{
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
        -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
                width: 0%;
    }
    100%{
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
                width: 43.9px;
    }
}

</style>
