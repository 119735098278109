<template>
    <div class="">
        <div class="bg-blue">
            <v-container>
                <div class="py-10 py-md-10  my-md-10 mt-lg-16 pt-lg-10">
                    <h1 class="h1 montserrat text-center text-md-left white--text">Our Projects</h1>
                    <v-col cols="12" lg="10" class="my-6 opensans text-center  text-md-left white--text ">At GBMF we create, develop and integrate a wide range of custom software development solutions for any OS, browser, or device. We empower businesses with up-to-date and adaptable digital solutions that fit the needs of their users and unlock the opportunities to reach their business goals.<br/>Explore the selected projects of our work, as doing is better than just speaking.
</v-col>
                </div>
            </v-container>
        </div>
        <v-container class="py-16">
            <wait-loading v-if="loading"/>
            <v-row>
                <v-col v-for="(item,i) in ourwork" :key="i" class="workblock" cols="12" sm="6">
                    <work-block :item="item" />
                </v-col>
            </v-row>
        </v-container>
        <quest />
    </div>
</template>

<script>
import WorkBlock from '@/components/base/WorkBlock.vue'
import WaitLoading from '@/components/base/WaitLoading.vue'
import Quest from '@/components/page/quest.vue'


export default {
    metaInfo() {
        return {
            meta: [
                { name: 'description', content:  'Daring projects that empower your business. GBMF delivers avant-garde digital solutions that advance your business and help you achieve your goals however ambitious they are.'},
                { property: 'og:title', content: "GBMF Projects - web apps, aggregators, and mobile apps for various industries"},
                { property: 'og:site_name', content: 'GBMF'},
                { property: 'og:type', content: 'website'},
                { name: 'robots', content: 'index,follow'}
            ]
        }
    },
    components:{
        WorkBlock,
        Quest,
        WaitLoading
    },
    data:()=>({
        ourwork:[],
        loading: true,
    }),
    created() {
        this.$emit('headerClass', 'headerblue');
    },
    mounted(){
        this.getWorks();
    },
    methods: {
        async getWorks(){
            if(this.ourwork.length < 1){
                await this.axios.get(process.env.VUE_APP_API + 'work').then(response => {
                    this.ourwork = response.data;
                    this.loading = false;
                })
            }
        },
    }
}
</script>

<style type="text/scss">
.firstletterblue::first-letter{
    color: #38A4DC;
}
</style>
