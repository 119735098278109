<template>
    <div class="careers">
        <div class="bg-blue">
            <v-container>
                <div class="py-10 py-md-10  my-md-10">
                    <h1 class="h1 montserrat text-center text-md-left white--text">Vacancies</h1>
                    <v-col cols="12" lg="8" class="h5 my-6 opensans text-center  text-md-left white--text ">Apart from being a highly client-oriented company, there’s so much we do for those who work under the roof of GBMF. Programmers, designers, app developers, and all kinds of fancy people work as a single team to make our clients’ dreams come true. We’re open to new candidates who share our vision and want to embrace creative work.</v-col>
                </div>
            </v-container>
        </div>
        <div class="my-sm-16">
            <v-container>
                <v-row>
                    <v-col class="mt-10" cols="12" lg="4">
                        <title-dotted title="Job Lists"/>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <wait-loading v-if="loading"/>
                        <v-row>
                            <v-col cols="12" sm="6" v-for="(item,i) in jobs" :key="i">
                                <job-card :item="item" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="mb-6">
            <v-container>
                <we-are />
            </v-container>
        </div>
        <div class="pt-6 contact" style="background-color: #f4f7ff">
            <v-container>
                <v-row>
                    <v-col cols="12" lg="4">
                        <div class="mt-10">
                            <p class="h3 fontw-700 mb-2">United Kingdom</p>
                            <p class="h6 mb-4">London,1st Floor, 2 Woodberry Grove <br/>Finchley N12 0DR</p>
                            <p class="h5 fontw-700 d-flex align-center mb-2">
                                <a href="tel:+44 741-832-3492" class="dark1">
                                    <img :src="require('@/assets/icon/phone-call.svg')" class="mr-2" alt="">+44 741-832-3492
                                </a>
                            </p>
                            <p class="h5 fontw-700 d-flex align-center">
                                <a href="mailto:hr@gbmf.tech" class="dark1">
                                    <img :src="require('@/assets/icon/email-write.svg')" class="mr-2" alt="">hr@gbmf.tech
                                </a>
                            </p>
                        </div>

                        <div class="mt-8">
                            <p class="h3 fontw-700 mb-2">USA</p>
                            <p class="h6 mb-4">Citrus Heights, California 95610<br/>7610 Auburn Blvd 4</p>
                            <p class="h5 fontw-700 d-flex align-center">
                                <a href="mailto:hr@gbmf.tech" class="dark1">
                                    <img :src="require('@/assets/icon/email-write.svg')" class="mr-2" alt="">hr@gbmf.tech
                                </a>
                            </p>
                        </div>
	
	                    <div class="mt-10">
		                    <p class="h3 fontw-700 mb-2">Switzerland</p>
		                    <p class="h6 mb-4">Badenerstrasse 47, 8004 Zürich</p>
		                    <p class="h5 fontw-700 d-flex align-center">
			                    <a href="mailto:hr@gbmf.tech" class="dark1">
				                    <img :src="require('@/assets/icon/email-write.svg')" class="mr-2" alt="">hr@gbmf.tech
			                    </a>
		                    </p>
	                    </div>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <div class="">
                            <div class="card pa-10 bg-white">
                                <div class="h3 dark1 fontw-700 mb-6">Request to Contact</div>
                                <v-form
                                    lazy-validation
                                    ref="form"
                                    v-model="valid"
                                >
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6" for="">Your name</label>
                                            <v-text-field
                                                v-model="form.name"
                                                color="#323232"
                                                class="py-0"
                                                :rules="requiredRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6" for="">Your Email</label>
                                            <v-text-field
                                                v-model="form.email"
                                                color="#323232"
                                                class="py-0"
                                                :rules="emailRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6" for="">Phone number</label>
                                            <v-text-field
                                                v-model="form.phone"
                                                color="#323232"
                                                class="py-0"
                                                :rules="phoneRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6" for="">Add your CV</label>
                                            <v-file-input
                                                v-model="form.cv"
                                                solo
                                                flat
                                                placeholder="Choose File"
                                                color="#1E5CFB"
                                                class="py-0 fileinout bg-transparent"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <div class="d-flex align-md-center flex-column flex-md-row">
                                        <v-btn
                                            class="px-14 py-7 white--text apply fontw-700 removebefore mb-4 mb-md-0"
                                            color="#1E5CFB"
                                            depressed
                                            @click="apply"
                                          >
                                            Apply
                                          </v-btn>
                                          <div class="ml-md-6 h6">
                                              By submitting the form you <br/>agree with <router-link :to="{ name: 'frontend.terms', params: {} }" class="dark1 text-decoration-underline">terms and conditions</router-link>
                                          </div>
                                    </div>
                                </v-form>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import JobCard from '@/components/base/JobCard'
import TitleDotted from '@/components/base/TitleDotted.vue'
import WeAre from '@/components/page/weare/whyus'
import ValidationMixin from '@/mixins/validationMixin.js';
import WaitLoading from '@/components/base/WaitLoading.vue'
import { serialize } from 'object-to-formdata';

export default {
    metaInfo() {
        return {
            meta: [
                { name: 'description', content:  'GBMF welcomes talented and creative people. Over 16 vacancies open for designers, programmers, app developers and other purposeful professionals from the IT niche.'},
                { property: 'og:title', content: "Vacancies at GBMF: become part of a success-driven team"},
                { property: 'og:site_name', content: 'GBMF'},
                { property: 'og:type', content: 'website'},
                { name: 'robots', content: 'index,follow'}
            ]
        }
    },
    components: {
        JobCard,
        WeAre,
        TitleDotted,
        WaitLoading
    },
    mixins: [
        ValidationMixin
    ],
    data: () => ({
        valid: false,
        jobs: [],
        form: {
            phone: '',
            email: '',
            text: '',
            cv: null
        },
        loading: true,
    }),
    created() {
        this.$emit('headerClass', 'headerblue');
    },
    mounted(){
        this.getJobs();
    },
    methods: {
        async getJobs(){
            await this.axios.get(process.env.VUE_APP_API + 'careers').then(response => {
                this.jobs = response.data;
                this.loading = false;
            })
        },
        apply(){
            if(this.$refs.form.validate()){
                this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

                const formData = serialize(
                    this.form,
                    {
                        indices: true,
                        allowEmptyArrays: true
                    }
                );
                formData.append('_method', 'PUT');

                this.axios.post(
                    process.env.VUE_APP_API + 'careers',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                    this.$refs.form.reset();
                });
            }
        }
    }
}
</script>

<style lang="scss">
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
    background: transparent;
}
.careers{
    .contact{
        .card{
            background: #FFFFFF;
            border-radius: 40px;
            input{
                margin-bottom: 6px;
            }
            .v-file-input{
                .v-file-input__text, button{
                    color: #1E5CFB;
                }
            }
            .apply{
                border-radius: 48px;
            }
            @media all and (min-width: 930px){
                .apply:hover::before{
                    background-color: #1E5CFB;
                    opacity: 0;
                }
            }
        }
    }
}
</style>
