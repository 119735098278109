<template>
    <v-btn class="btn-refresh py-7 px-8" :ripple="false" depressed @mouseover="mouseOver" @mouseleave="mouseLeave">
        <img class="mr-3" v-show="!active " :src="require('@/assets/icon/refreshblue.svg')" alt="">
        <img class="mr-3" v-show="active" :src="require('@/assets/icon/refreshwhite.svg')" alt="">
        <slot/>
    </v-btn>
</template>

<script>
export default {
    name:"ButtonCircle",
    data:() => ({
        active: false
    }),
    methods: {
        mouseOver: function(){
            if(this.$vuetify.breakpoint.lgAndUp)
                this.active = true;
        },
        mouseLeave: function(){
            this.active = false;
        }
    }
}
</script>
<style lang="scss">
@import "@/sass/variables.scss";

.btn-refresh{
    transition: 0.3s;
    font-weight: bold !important;
    font-size:  $h6 !important;
    background-size: auto;
    background-color: $white !important;
    color: $blue1 !important;
    border-radius: 48px !important;
    border: 1px solid rgba(50, 50, 50, 0.1) !important;
    text-transform: uppercase !important;
}
.btn-refresh::before{
    content: none;
}
@media all and (min-width: 930px){
    .btn-refresh:hover{
        font-weight: bold !important;
        font-size:  $h6 !important;
        background-color: $blue1 !important;
        color: $white !important;
        border-radius: 48px !important;
        text-transform: uppercase !important;
    }
}
.btn-refresh.disabled{
    background-color: $white !important;
    color: $blue3 !important;
    opacity: 0.2 !important;
    border-radius: 48px !important;
    border: 1px solid rgba(50, 50, 50, 0.1) !important;
    text-transform: uppercase !important;
}
</style>
