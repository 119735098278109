<template>
	<div class="preloader p-absolute w-100 h-100 d-flex align-self-center justify-center">
		<div class="d-flex flex-column align-center">
			<!-- <img class="imganim" :src="require('@/assets/icon/preloaderlogo.svg')"> -->
			<div class="innertext d-flex flex-column mb-16 mb-md-0">
				<div class="items">
					<p class="item mb-0 h3 uppercase montserrat bold white--text flex-grow-0 flex-shrink-0 text-center">Web App</p>
					<p class="item mb-0 h3 uppercase montserrat bold white--text flex-grow-0 flex-shrink-0 text-center">Mobile App</p>
					<p class="item mb-0 h3 uppercase montserrat bold white--text flex-grow-0 flex-shrink-0 text-center">UI/UX Design</p>
					<p class="item mb-0 h3 uppercase montserrat bold white--text flex-grow-0 flex-shrink-0 text-center">Software</p>
					<div class="text-center">
						<img src="@/assets/logo/logo-white.svg" class="mt-5" alt="">
					</div>
				</div>
			</div>
				<!-- <div class="d-flex ml-5 ma-0 animated-text-preloader align-self-center ">
					<p class="ma-0 h3 uppercase montserrat bold white--text flex-grow-0 flex-shrink-0" style="align-items:center;">Web App</p>
					<!-- <p class="h3 uppercase montserrat bold white--text" style="align-items:center;">Mobile App</p>
					<p class="h3 uppercase montserrat bold white--text" style="align-items:center;">UI/UX Design</p>
					<p class="h3 uppercase montserrat bold white--text" style="align-items:center;">Software</p> ->
				</div> -->
		</div>
	</div>
</template>


<script>
export default {
	name: 'preloader',
}
</script>

<style lang="scss">

.preloader{
	display: flex;
	flex-direction: column;
	background-color: #1e5cfb;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9999;

	// .imganim{
	// 	-webkit-animation: logoanimation 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	// 	animation: logoanimation 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	// }
	.animated-text-preloader{
		overflow: hidden;
		// animation: textshowanimation 5s ease;
	}
	.innertext{
		width: 280px;
		height: 80px;
		overflow: hidden;
		-webkit-animation: innertextanimation 0.45s linear;
		animation: innertextanimation 0.45s linear;

		.item{
			height: 54px;
			margin-top: 16px;
		}
		.items{
			margin-top: -280px;
			-webkit-animation: innertextitemsanimation 5s ease-in;
					animation: innertextitemsanimation 5s ease-in;
		}
		// .items:first-of-type{
		// 	margin-top: 0;
		// }

	}
	// .move-right{
	// 	overflow: hidden;
	//
	// 	animation: leftright 1s ease-in;
	// }

	@-webkit-keyframes logoanimation{
		0%{
			-webkit-transform: scale(0.1);
			transform: scale(0.1);
		}
		100%{
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
	@keyframes logoanimation{
		0%{
			-webkit-transform: scale(0.1);
			transform: scale(0.1);
		}
		100%{
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
	@-webkit-keyframes innertextanimation{
		0%{
			width: 0px;
		}
		80%{
			width: 0px;
		}
		100%{
			width: 250px;
		}
	}
	@keyframes innertextanimation{
		0%{
			width: 0px;
		}
		80%{
			width: 0px;
		}
		100%{
			width: 250px;
		}
	}
	@-webkit-keyframes innertextitemsanimation{
		0%{
			margin-top: 0px;
		}

		17%{
			margin-top: 0px;
			opacity: 1;
		}
		20%{
			margin-top: -50px;
			opacity: 0;
		}
		23%{
			margin-top: -70px;
			opacity: 1;
		}
		37%{
			margin-top: -70px;
			opacity: 1;
		}
		40%{
			margin-top: -120px;
			opacity: 0;
		}
		43%{
			margin-top: -140px;
			opacity: 1;
		}

		57%{
			margin-top: -140px;
			opacity: 1;
		}
		60%{
			margin-top: -190px;
			opacity: 0;
		}
		63%{
			margin-top: -210px;
			opacity: 1;
		}

		77%{
			margin-top: -210px;
			opacity: 1;
		}
		80%{
			margin-top: -260px;
			opacity: 0;
		}
		83%{
			margin-top: -280px;
			opacity: 1;
		}

		100%{
			margin-top: -280px;
		}
	}
	@keyframes innertextitemsanimation{
		0%{
			margin-top: 0px;
		}

		17%{
			margin-top: 0px;
			opacity: 1;
		}
		20%{
			margin-top: -50px;
			opacity: 0;
		}
		23%{
			margin-top: -70px;
			opacity: 1;
		}
		37%{
			margin-top: -70px;
			opacity: 1;
		}
		40%{
			margin-top: -120px;
			opacity: 0;
		}
		43%{
			margin-top: -140px;
			opacity: 1;
		}

		57%{
			margin-top: -140px;
			opacity: 1;
		}
		60%{
			margin-top: -190px;
			opacity: 0;
		}
		63%{
			margin-top: -210px;
			opacity: 1;
		}

		77%{
			margin-top: -210px;
			opacity: 1;
		}
		80%{
			margin-top: -260px;
			opacity: 0;
		}
		83%{
			margin-top: -280px;
			opacity: 1;
		}

		100%{
			margin-top: -280px;
		}
	}
}
</style>
